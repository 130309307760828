import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { HttpService } from "../http/service";
import {
  Transaction,
  Payment,
  EditPayment,
  AddPayment,
  PayPeiTransaction,
  TransactionPayload,
  PayTridiTransaction,
  PayPeiOperation,
  Coupon,
} from "../../models/payment-transaction.model";
import { ExpensesDetail } from "../../models/expenses-detail.model";
import { BehaviorSubject } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { RoutePath } from "../../constants/globals.constant";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CvuDetail } from "../../models/cvu-detail.model";

@Injectable()
export class PaymentTransactionService {
  payCachePeiData = new PayPeiTransaction();
  payCachePeiOperationData = new PayPeiOperation();
  payCacheTridiData = new PayTridiTransaction();
  transactionCache: Transaction | any = {};
  allowRestartFlow = true;

  _hashParam: string = "";
  _firstLoad = true;
  _adminPlatform: boolean = false;

  constructor(
    private httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient
  ) {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((data: NavigationEnd) => {
        const routerUrl = data.url.slice(1).split("/")[0].split("?")[0];
        if (routerUrl === RoutePath.initAdminPayment) {
          this._hashParam = this.route.snapshot.queryParamMap.get("hash") || "";
          if (this.firstLoad) {
            this._adminPlatform = this._hashParam !== "";
          }
        }
      });
  }

  public createPaymentTransaction(
    expensesDetail: ExpensesDetail[],
    email?: string,
    name?: string,
    amount?: string
  ): Observable<Transaction> {
    const payload = new TransactionPayload();
    payload.payments = this.prepareCreatePaymentTransactionData(expensesDetail);
    if (email) payload.ownerEmail = email;
    if (name) payload.ownerName = name;
    if (amount)
      payload.payments[0].amount =
        parseFloat(amount) - expensesDetail[0].paidAmount;
    return this.httpService.create("payment-transaction", payload).pipe(
      map((response) => {
        return response;
      }),
      catchError((e) => throwError(e))
    );
  }

  public getPaymentTransaction(
    transactionId: string,
    force = false
  ): Observable<any> {
    if (!force) {
      const cache = this.getCacheTransaction();
      if (cache && cache.id === transactionId) {
        this._adminPlatform = cache.isAdmin;
        this.validateAdminTransaction(cache);
        return new BehaviorSubject(cache);
      }
    }

    return this.httpService.get("payment-transaction", transactionId).pipe(
      map((response: any) => {
        this.validateAdminTransaction(response);
        this.saveCacheTransaction(response);
        this._adminPlatform = response.isAdmin;
        return response;
      }),
      catchError((e) => throwError(e))
    );
  }

  public getPayzenFormToken(
    id: string,
    force: boolean = false
  ): Observable<any> {
    return this.httpService
      .get("get-payzen-form-token", { force: force }, id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => throwError(e))
      );
  }

  public getPayzenInstallmentsFormToken(
    id: string,
    force: boolean = false
  ): Observable<any> {
    return this.httpService
      .get("get-payzen-installments-form-token", { force: force }, id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => throwError(e))
      );
  }

  public updatePaymentTransaction(
    objectData: Object,
    transactionId: string
  ): Observable<any> {
    return this.httpService
      .patch("update-payment-transaction", objectData, transactionId)
      .pipe(
        map((response: any) => {
          this.saveCacheTransaction(response);
          this._adminPlatform = response.isAdmin;
          return response;
        }),
        catchError((e) => throwError(e))
      );
  }

  public ApplyCoupon(
    idPaymentTransaction: string,
    coupon: Coupon
  ): Observable<any> {
    return this.httpService
      .patch("apply-coupon", coupon, idPaymentTransaction)
      .pipe(
        map((response: any) => {
          this.saveCacheTransaction(response);
          return response;
        }),
        catchError((e) => throwError(e))
      );
  }

  public KeepAliveCoupons(idPaymentTransaction: string): Observable<any> {
    return this.httpService
      .patch("keep-alive-coupons", null, idPaymentTransaction)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => throwError(e))
      );
  }

  public DeleteCoupon(idPaymentTransaction: string): Observable<any> {
    return this.httpService
      .patch("delete-coupon", null, idPaymentTransaction)
      .pipe(
        map((response: any) => {
          this.saveCacheTransaction(response);
          return response;
        }),
        catchError((e) => throwError(e))
      );
  }

  public addPayments(
    expensesDetail: ExpensesDetail[],
    transaction: Transaction
  ): Observable<Payment> {
    this.removeCacheTransaction();
    const payments = this.prepareCreatePaymentTransactionData(
      expensesDetail,
      transaction.payments
    );
    return this.httpService
      .edit("update-payments", payments, transaction.id)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((e) => throwError(e))
      );
  }

  public updatePayments(
    transactionId: string,
    payments: Payment[]
  ): Observable<Payment[]> {
    const payementData = this.prepareEditPaymentsData(payments);
    return this.httpService
      .edit("update-payments", payementData, transactionId)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((e) => throwError(e))
      );
  }

  public removePayment(
    transactionId: string,
    paymentId: string
  ): Observable<{}> {
    this.removeCacheTransaction();
    return this.httpService.delete("remove-payment", transactionId, paymentId);
  }

  public payPeiTransaction(
    transactionId: string,
    payData: PayPeiTransaction
  ): Observable<{}> {
    payData.cardNumber = payData.cardNumber.split(" ").join("");
    this.setCachePayPeiData(payData);
    return this.httpService
      .create("pay-pei-transaction", payData, transactionId)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((e) => throwError(e))
      );
  }

  public getCvuDetailByPaymentTransaction(transactionId: string): Observable<CvuDetail> {
    return this.httpService
      .get("cvu-detail", null, transactionId)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((e) => throwError(e))
      );
  }

  public payTridiTransaction(
    transactionId: string,
    payData: PayTridiTransaction
  ): Observable<{}> {
    this.setCachePayTridiData(payData);
    return this.httpService
      .create("pay-tridi-transaction", payData, transactionId)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((e) => throwError(e))
      );
  }

  public payPeiPopupState(
    transactionId: string,
    payData: PayPeiOperation
  ): Observable<{}> {
    this.setCachePayPeiOperationData(payData);
    return this.httpService
      .edit("change-pei-status", payData, transactionId)
      .pipe(catchError((e) => throwError(e)));
  }

  public getPeiHash(
    transactionId: string,
    payData: PayPeiOperation
  ): Observable<any> {
    this.setCachePayPeiOperationData(payData);
    return this.httpService.get("get-pei-hash", transactionId).pipe(
      map((response) => {
        return response;
      }),
      catchError((e) => throwError(e))
    );
  }

  public saveCacheTransaction(transaction: Transaction | any) {
    sessionStorage.setItem("transactionCache", JSON.stringify(transaction));
  }

  public getCacheTransaction(): Transaction | any {
    return JSON.parse(sessionStorage.getItem("transactionCache"));
  }

  public getCachePayPeiData(): PayPeiTransaction {
    return this.payCachePeiData;
  }

  public getCachePayTridiData(): PayTridiTransaction {
    return this.payCacheTridiData;
  }

  public removeCachePayPeiData(): void {
    this.payCachePeiData = null;
  }

  public removeCachePayTridiData(): void {
    this.payCacheTridiData = null;
  }

  public removeCachePayPeiOperationData(): void {
    this.payCachePeiOperationData = null;
  }

  public setCachePayPeiData(payData: PayPeiTransaction): void {
    this.payCachePeiData = payData;
  }

  public setCachePayTridiData(payData: PayTridiTransaction): void {
    this.payCacheTridiData = payData;
  }

  public setCachePayPeiOperationData(payData: PayPeiOperation): void {
    this.payCachePeiOperationData = payData;
  }

  public setAllowRestartFlow(value: boolean): void {
    this.allowRestartFlow = value;
  }

  public canRestartFlow(): boolean {
    return this.allowRestartFlow;
  }

  public removeCacheTransaction(): void {
    sessionStorage.removeItem("transactionCache");
  }

  private prepareEditPaymentsData(payments: Payment[]): EditPayment[] {
    const editPayments = new Array<EditPayment>();
    payments.forEach((payment: Payment) => {
      editPayments.push(new EditPayment(payment));
    });
    return editPayments;
  }

  private prepareCreatePaymentTransactionData(
    expensesDetail: ExpensesDetail[],
    transactionPayments?: Payment[]
  ): AddPayment[] {
    const payments = new Array<AddPayment>();
    if (transactionPayments && transactionPayments.length) {
      transactionPayments.forEach((payment: Payment) => {
        payments.push(
          new AddPayment(payment.buildingExpense, payment.id, payment.amount)
        );
      });
    }

    expensesDetail.forEach((expense: ExpensesDetail) => {
      payments.push(new AddPayment(expense));
    });

    return payments;
  }

  // Funcionalidad de admin platform

  getAdminData(hash: string): Observable<any> {
    const cache = this.getCacheTransaction();
    if (cache && hash === this._hashParam) {
      return new BehaviorSubject(cache);
    }

    return this.httpService.get("payment-transaction-admin", null, hash).pipe(
      map((data: any) => {
        sessionStorage.setItem(
          "contractAdminPaymentDto",
          JSON.stringify(data.contractAdminPaymentDto)
        );
        return (this.transactionCache = data.detailedPaymentTransactionDto);
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  get hashParam(): string {
    return this._hashParam;
  }

  get isAdminPlatform(): boolean {
    return this._adminPlatform;
  }

  get firstLoad(): boolean {
    return this._firstLoad;
  }

  set firstLoad(state) {
    this._firstLoad = state;
  }

  getCacheAdminData() {
    return JSON.parse(sessionStorage.getItem("contractAdminPaymentDto"));
  }
  removeCacheAdminData() {
    return sessionStorage.removeItem("contractAdminPaymentDto");
  }

  private validateAdminTransaction(transaction: Transaction) {
    if (transaction.isAdmin) {
      if (this.getCacheAdminData() === null) {
        this.router.navigate([RoutePath.resultMessage, "not-found"]);
      }
    } else {
      this.removeCacheAdminData();
    }
  }

  public getPdf(url): Observable<any> {
    const headers = new HttpHeaders({
      accept: "application/pdf",
    });
    const uri = url;
    return this.httpClient.get(uri, {
      headers: headers,
      observe: "response",
      responseType: "blob",
    });
  }

  public subscribeEmail() {
    let isSubscribe = JSON.parse(sessionStorage.getItem("isSubscribe"));
    let isSubscribeIntention = JSON.parse(
      sessionStorage.getItem("emailSubscribeIntention")
    );

    if (!isSubscribe && isSubscribeIntention) {
      let emailSubscriptionDto = JSON.parse(
        sessionStorage.getItem("emailSubscription")
      );
      this.httpService
        .create("subscribe-commercial-messages", emailSubscriptionDto)
        .subscribe((response) => {
          let emailSubscription = response;
        });
    }
  }

  public saveSubscriptionDto(subscriptionDto: any) {
    sessionStorage.setItem(
      "emailSubscription",
      JSON.stringify(subscriptionDto)
    );
  }

  public saveSubscriptionStatus(isSubscribe: boolean) {
    sessionStorage.setItem("isSubscribe", JSON.stringify(isSubscribe));
  }

  public saveSubscriptionIntention(isSubscribeIntention: boolean) {
    sessionStorage.setItem(
      "emailSubscribeIntention",
      JSON.stringify(isSubscribeIntention)
    );
  }

  public getTicket(transactionId: string) {
    return this.httpService.getBlob("get-ticket", null, transactionId).pipe(
      map((response) => {
        return response;
      }),
      catchError((e) => throwError(e))
    );
  }
}
